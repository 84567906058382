import React, { useState, useEffect } from "react";
import "./userList.css";
import { Breadcrumb, Table, Image, Pagination } from "antd";
import {
  bellIcon,
  crossIcon,
  homeIcon,
  redTrash,
  trueIcon,
  userIcon,
} from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import SearchComp from "../../components/searchCom/search";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);

  const getAllUsers = () => {
    let getRes = (res) => {
      // console.log("res of user list", res);
      setUsers(res?.data?.data);
    };

    callApi("GET", routes.getAllUsers, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const deleteUser = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      setUpdateApi(true);
    };

    callApi(
      "DELETE",
      `${routes.deleteUser}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUsers();
  }, [updateApi]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      className: "role-name-column-header",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      className: "role-name-column-header",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      align: "center",
      width: 150,
    },

    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      className: "type-name-column-header",
      sorter: (a, b) => a.email.localeCompare(b.lastName),
      width: 200,
      // filteredValue: [search],
      // onFilter: (value, record) => {
      //   return record?.email?.toLowerCase()?.includes(value.toLowerCase());
      // },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Profile",
      dataIndex: "profile",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Notify",
      dataIndex: "notify",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
    },
  ];

  const data = users
    ?.filter(
      (item) =>
        item.firstName.toLowerCase().includes(search.toLowerCase()) ||
        item.lastName.toLowerCase().includes(search.toLowerCase()) ||
        item.email.toLowerCase().includes(search.toLowerCase())
    )
    ?.map((item, index) => {
      return {
        key: index,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        phoneNumber: item?.number,
        profile: (
          <div className="product-list-image">
            <Image width={50} src={item?.image} alt="profile-image" />
          </div>
        ),
        verified: (
          <div className="server-roles-trash-btn">
            <img src={item?.verified ? trueIcon : crossIcon} alt="" />
          </div>
        ),
        notify: (
          <img
            onClick={() =>
              navigate("/pushnotification", {
                state: { userId: item?._id, email: item?.email },
              })
            }
            className="bell-notify-icon"
            src={bellIcon}
          />
        ),
        username: item?.username,
        delete: (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteUser(item?._id)}
          >
            <img src={redTrash} alt="" />
          </div>
        ),
      };
    });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Users</h1>
      </div>
      <SearchComp
        placeHolder={"Search "}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={{ Pagination, pageSize: 5 }}
          className="ant-table-wrapper .ant-table-thead"
        ></Table>
      </div>
    </div>
  );
};

export default UserList;
